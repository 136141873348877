var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 问答列表 ")]},proxy:true},{key:"input",fn:function(){return [_c('span',{staticClass:"tag"},[_vm._v("发布时间: ")]),_c('a-range-picker',{attrs:{"valueFormat":"YYYY-MM-DD HH:MM:SS","show-time":""},model:{value:(_vm.findtime),callback:function ($$v) {_vm.findtime=$$v},expression:"findtime"}}),_c('a-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}}),_c('a-button',{staticClass:"btn",attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.onSearch()}}},[_vm._v("搜索")]),_c('a-button',{staticClass:"all_boder_btn btn",on:{"click":function($event){return _vm.toDetail('', '')}}},[_vm._v("新增")]),_c('a',{staticClass:"left",attrs:{"href":_vm.$config.target+'/hxclass-management/information/answer/template',"target":"_blank","rel":"noopener noreferrer"}},[_c('a-button',{staticStyle:{"margin-right":"10px"}},[_vm._v("下载模板")])],1),_c('a-upload',{attrs:{"showUploadList":false,"name":"file","customRequest":_vm.fileAction}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("批量导入")])],1)]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-tabs',{on:{"change":_vm.callback},model:{value:(_vm.qaTypeId),callback:function ($$v) {_vm.qaTypeId=$$v},expression:"qaTypeId"}},_vm._l((_vm.sortList),function(item){return _c('a-tab-pane',{key:item.answerId,attrs:{"tab":item.categoryName}})}),1)],1),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item, index) { return index; },"columns":_vm.columns,"loading":_vm.loading,"data-source":_vm.tableData,"pagination":{
      total: _vm.total,
      current: _vm.pageNumber,
      defaultPageSize: _vm.pageSize,
      showSizeChanger: true,
      showTotal: function (total, range) {
        return ("共" + total + "条");
      },
    }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"operation",fn:function(item, row, index){return [_c('span',{staticClass:"blueText"},[(!(_vm.pageNumber == 1 && index === 0))?_c('span',{on:{"click":function($event){return _vm.moveup(row.qaId)}}},[_vm._v("上移 | ")]):_vm._e(),((_vm.pageNumber - 1) * _vm.pageSize + index + 1 < _vm.total)?_c('span',{on:{"click":function($event){return _vm.movedown(row.qaId)}}},[_vm._v("下移 | ")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.editItem(row)}}},[_vm._v("编辑")]),_vm._v(" | "),_c('span',{on:{"click":function($event){return _vm.delItem(row.qaId)}}},[_vm._v("删除")])])]}}])}),_c('LeadingIn',{attrs:{"columns":_vm.columnsss,"successList":_vm.successList,"failureList":_vm.failureList,"visible":_vm.importVisible,"handleCancel":_vm.modelhandleCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }