<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 问答列表 </template>
      <template #input>
        <span class="tag">发布时间: </span>
        <a-range-picker
          valueFormat="YYYY-MM-DD HH:MM:SS"
          v-model="findtime"
          show-time
        ></a-range-picker>
        <a-input placeholder="请输入关键字" v-model="key" />
        <a-button type="primary" class="btn" :loading="loading" @click="onSearch()"
          >搜索</a-button
        >
        <a-button class="all_boder_btn btn" @click="toDetail('', '')"
          >新增</a-button
        >
        <a
          :href="$config.target+'/hxclass-management/information/answer/template'"
          target="_blank"
          rel="noopener noreferrer"
          class="left"
        >
          <a-button style="margin-right: 10px">下载模板</a-button>
        </a>
        <a-upload
          :showUploadList="false"
          name="file"
          :customRequest="fileAction"
        >
          <a-button type="primary">批量导入</a-button>
        </a-upload>
      </template>
    </HeaderBox>

    <div class="table-box">
      <a-tabs v-model="qaTypeId" @change="callback">
        <a-tab-pane
          :tab="item.categoryName"
          v-for="item in sortList"
          :key="item.answerId"
        ></a-tab-pane>
      </a-tabs>
    </div>

    <a-table
      class="table-template"
      :rowKey="(item, index) => index"
      :columns="columns"
      :loading="loading"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total: total,
        current: pageNumber,
        defaultPageSize: pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, index">
        <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
        {{ index + 1 }}
      </template>

      <template slot="operation" slot-scope="item, row, index">
        <span class="blueText">
          <span
            v-if="!(pageNumber == 1 && index === 0)"
            @click="moveup(row.qaId)"
            >上移 |
          </span>

          <!-- v-if="!((total % pageSize) - 1 == index)"   原判断-->

          <span
            v-if="(pageNumber - 1) * pageSize + index + 1 < total"
            @click="movedown(row.qaId)"
            >下移 |
          </span>
          <span @click="editItem(row)">编辑</span> |
          <span @click="delItem(row.qaId)">删除</span>
        </span>
      </template>
    </a-table>

    <!-- 数据弹窗 -->
    <LeadingIn
      :columns="columnsss"
      :successList="successList"
      :failureList="failureList"
      :visible="importVisible"
      :handleCancel="modelhandleCancel"
    />
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import LeadingIn from "@/components/leadingIn.vue"; // 导入组件
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "标题",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "发布时间",
    align: "center",
    dataIndex: "createTime",
  },
  // {
  //   title: "浏览量",
  //   align: "center",
  //   dataIndex: "num",
  // },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
const columnsss = [
  {
    title: "标题",
    align: "center",
    dataIndex: "title",
  },
  {
    title: "回答",
    align: "center",
    dataIndex: "content",
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox, LeadingIn }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      loading:false,
      key: "", // 关键字
      findtime: [], // 筛选时间
      qaTypeId: "", // 分类id
      sortList: [], // 分类列表
      userType: 1,
      columns,
      tableData: [],
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      // 弹框
      columnsss,
      importVisible: false, // 导入弹框
      successList: null, // 正确数据
      failureList: null, // 错误数据
    };
  }, // 事件处理器
  methods: {
    modelhandleCancel() {
      this.importVisible = false;
    },
    // 搜索
    onSearch() {
      this.pageNumber=1;
      this.getManageList();
    },
    // 导入基础信息
    fileAction(e) {
      this.$ajax({
        url: "/hxclass-management/information/answer/batch",
        method: "put",
        headers: [{ type: "file" }],
        params: {
          file: e.file,
          name: "file",
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.successList = res.data.success
          this.failureList = res.data.fail
          this.importVisible = true
          this.getManageList();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 下载错误excel
    downloadExcel() {
      if (!this.failureList.length) {
        return;
      }
      this.$ajax({
        url: "/hxclass-management/information/answer/export/answer/wrong",
        method: "post",
        headers: [{type: 'file'}],
        params: {
          errorJson: JSON.stringify(this.failureList)
        },
        responseType: 'blob'
      }).then((res) => {
        this.blobDownload(res,'错误文件')
      });
    },

    // blob文件转excel
    blobDownload(data,name){
      let m=this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content],{type:"application/vnd.ms-excel;charset=utf-8"});
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name+".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },

    // 导入正确数据
    importSuccessList() {
      this.$ajax({
        url: "/hxclass-management/information/answer/import/insert",
        method: "POST",
        params: this.successList,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.getManageList();
          this.importVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 上移
    moveup(e) {
      let previndex = '';
      this.tableData.map((item,index)=>{
        if(item.qaId == e){
          previndex = index-1;
        }
      })
      // console.log(previndex)
      this.$ajax({
        url: "/hxclass-management/information/answer/up?id=" + e + '&oldId='+this.tableData[previndex].qaId,
        method: "put",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getManageList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 下移
    movedown(e) {
      let previndex = '';
      this.tableData.map((item,index)=>{
        if(item.qaId == e){
          previndex = index+1;
        }
      })
      // console.log(previndex)
      this.$ajax({
        url: "/hxclass-management/information/answer/down?id=" + e + '&oldId='+this.tableData[previndex].qaId,
        method: "put",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getManageList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 编辑
    editItem(e) {
      this.toDetail(e.qaId, e);
    },
    // 删除
    delItem(e) {
      this.$confirm({
        title: "确定删除吗?",
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/information/answer?id=" + e,
            method: "delete",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getManageList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
      
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    callback(e) {
      this.qaTypeId = e;
      this.getManageList();
    },
    toDetail(id, e) {
      if (e != "") {
        e["qaTypeId"] = this.qaTypeId;
      }
      this.$router.push({
        name: "QADetail",
        params: e,
      });
    },
    // 获取问答列表
    getManageList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/information/answer/list",
        method: "get",
        params: {
          endTime: this.findtime?.[1],
          key: this.key,
          qaTypeId: this.qaTypeId,
          starTime: this.findtime?.[0],
          page:1,
          size: 100000
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.records.length;
          if (!(res.data.length % this.pageSize) && this.pageNumber != 1) {
            if (res.data.length / this.pageSize < this.pageNumber) {
              this.pageNumber -= 1;
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取分类列表
    getSortList() {
      this.$ajax({
        url: "/hxclass-management/information/answer/category/list",
        method: "get",
        params:{
          page:1,
          size: 100
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.sortList = res.data.records;
          this.qaTypeId = res.data.records[0].answerId;
          this.getManageList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.getSortList(); // 获取分类列表
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;

  .btn {
    margin: 0 24px 14px 0;
  }

  .table-box {
    display: flex;
    justify-content: space-between;

    /deep/ .ant-tabs-bar {
      border-bottom: none;
    }

    /deep/.ant-tabs-bar {
      margin: 0;
    }

    .all_boder_btn {
      margin-top: 10px;
    }
  }
}
</style>
